import React from "react";
import './SearchBlock.css';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';


class SearchBlock extends React.Component {


    constructor(props){

        super(props);
        this.controllers = [];

    }

    componentDidMount() {

        if (this.props.searchTerm != "" && (this.props.companiesTotalCount === 0 || this.props.companiesTotalCount === null)) {

            const signal = this.initAbortController();
            this.props.searchSubmit(this.props.searchTerm, this.props.page, this.props.pageSize, this.props.token, signal);
        }
    }

    handleSubmit = (e) => {

        this.props.initiateSearch(this.props.history, e.target.value, 1, this.props.pageSize)
        this.debouncedCall(e.target.value)
    }

    initAbortController = () => {
        this.controllers.map(controller => controller.abort());
        this.controllers = []
        const controller = new AbortController();
        const { signal } = controller;
        this.controllers.push(controller);
        return signal
    }

    debouncedCall = debounce((value) => { 

        const signal = this.initAbortController();
        this.props.searchSubmit(value, 1, this.props.pageSize, this.props.token, signal);
     }, 400)

    render() {
        return (
            <div className="searchDiv">
                <SearchIcon fontSize='large' className="searchIcon" />
                <input className="searchInput" placeholder="IČO, Název firmy ..." onChange={this.handleSubmit} value={this.props.searchTerm} />
            </div>
        )
    }
}


export default SearchBlock;
