import React from "react";
import ErrorIcon from '@mui/icons-material/Error';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./Constraint.css";

class Constraint extends React.Component {

    getValueName = (valueId) => {
        if(valueId === "current_value"){
            return "hlavní rok"
        }
        else if(valueId === 'previous_value'){
            return "předchozí rok"

        }
        else if(valueId === 'current_netto'){
            return "aktuální netto"

        }
        else if(valueId === 'current_brutto'){
            return "aktuální brutto"

        }
        else if(valueId === 'current_korekce'){
            return "aktuální korekce"

        }
        else if(valueId === 'previous_netto'){
            return "přechozí netto"

        }
    }

    render() {

        /* TODO: It would be nice to replace the rule ID with name. */       

        const constraints = this.props.unsatisfiedConstraints.map((constraint, idx) => (
            <div className="rule" key={idx}>
                <math>{constraint.value.replace('=', "!=")}</math>
            </div>
        )
        )

        return (

            <div>

                {
                constraints.length > 0 ?

                    <div className="collapsableDiv unsatisfiedConstraintsDiv">

                        <div className="collapsableConstraintHeading" onClick={e => this.props.onClick(this.props.constraintType, e)}>
                            {

                                (this.props.uncollapsedList.includes(this.props.constraintType)) ?
                                    <ArrowDropDownIcon className="collapseArrow" style={{ fontSize: 50 }} />
                                    :
                                    <ArrowRightIcon className="collapseArrow" style={{ fontSize: 50 }} />
                            }

                            <div className="unsatisfiedConstraintsDivContent">
                                <ErrorIcon style={{ color: "var(--error)" }} />
                                <p className="constraintParagraph">Vyčtené data z dokumentu pro hodnotu <b>{this.getValueName(this.props.constraintType)}</b> nesplňují {constraints.length} součtových pravidel.</p>
                            </div>
                        </div>
                        <div id={this.props.constraintType} className={`${this.props.uncollapsedList.includes(this.props.constraintType) ? "uncollapsedConstraint" : "collapsedConstraint"}`}>
                            {constraints}
                        </div>
                    </div>

                    :

                    <div className="constraintsSuccess">
                        <CheckCircleIcon style={{ color: "var(--success)" }} />
                        <p className="constraintParagraph">Zdá se, že data z dokumentu pro hodnotu <b>{this.getValueName(this.props.constraintType)}</b> jsou v pořádku.</p>
                    </div>
            }

            </div>


        )
    }

}

export default Constraint;