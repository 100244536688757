import React from "react";
import './Footer.css';

class FooterComponent extends React.Component{

    render(){
        return(
            <div className="footerDiv">

            <div>
                <a href="/financni-vykazy">Co jsou finanční výkazy firem?</a>
                <a href="/ucetni-vykazy">Co je účetní uzávěrka firem?</a>
            </div>

            <div><a href="/kontakt">Kontakt</a></div>
            <div><a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik">Ceník</a></div>
            <p>Neručíme za správnost poskytovaných dat.</p>
            </div>
        )
    }

}

export default FooterComponent;