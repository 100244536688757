
function showAdverts(){
    return {
        type: 'SHOW_ADVERTS',
        showAdverts: true
    }
}

function hideAdverts(){
    return {
        type: 'HIDE_ADVERTS',
        showAdverts: false
    }
}

export {showAdverts, hideAdverts};