
function advertsReducer(state, action){

    switch(action.type){

        case 'SHOW_ADVERTS':

            return {
                ...state,
                //showAdverts: action.showAdverts
                showAdverts: false
            }

        case 'HIDE_ADVERTS':

                return {
                    ...state,
                    showAdverts: action.showAdverts
                }

        default:
            return state

    }

}

export {advertsReducer};