

function getDocument(){

    return {
        type: "GET_DOCUMENT"
    }

}

function getDocumentDone(document, anonymize){
    return {
        type: 'GET_DOCUMENT_DONE',
        document: document,
        anonymizeDocumentData: anonymize
    }
}

export {getDocument, getDocumentDone};