
function switchTab(activeTabId){
    return {
        type: 'SWITCH_DETAIL_TAB',
        activeTabId: activeTabId
    }
}

function getCompany(){
    return {
        type: 'GET_COMPANY'
    }
}

function companyFetched(company){
    return {
        type: 'COMPANY_FETCHED',
        company: company
    }
}

function getSimilarCompanies(){
    return {
        type: 'GET_SIMILAR_COMPANIES'
    }
}

function similarCompaniesFetched(companies){
    return {
        type: 'SIMILAR_COMPANIES_FETCHED',
        companies: companies
    }
}

function getBusinessCategories(){
    return {
        type: 'GET_BUSINESS_CATEGORIES'
    }
}

function businessCategoriesFetched(businessCategories){
    return {
        type: 'BUSINESS_CATEGORIES_FETCHED',
        businessCategories: businessCategories
    }
}

function getFinancialData(){
    return {
        type: 'GET_FINANCIAL_DATA'
    }
}

function financialDataFetched(financialData){
    return {
        type: 'FINANCIAL_DATA_FETCHED',
        financialData: financialData
    }
}

function getDocuments(){
    return {
        type: 'GET_DOCUMENTS'
    }
}

function documentsFetched(documents){
    return {
        type: 'DOCUMENTS_FETCHED',
        documents: documents
    }
}

export {switchTab, getCompany, companyFetched, getFinancialData, financialDataFetched, getDocuments, documentsFetched, getSimilarCompanies, similarCompaniesFetched, getBusinessCategories, businessCategoriesFetched};