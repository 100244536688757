import React from "react";
import './SearchGuide.css';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RegionTile from "./RegionTile";
import StatsDashboard from "../MacroeconomicStats/ForeignCompaniesOwners";

const regionsMap = {
    "kralovehradecky-kraj": "Královéhradecký kraj",
    "stredocesky-kraj": "Středočeský kraj",
    "karlovarsky-kraj": "Karlovarský kraj",
    "plzensky-kraj": "Plzeňský kraj",
    "pardubicky-kraj": "Pardubický kraj",
    "zlinsky-kraj": "Zlínský kraj",
    "kraj-vysocina": "Kraj Vysočina",
    "hlavni-mesto-praha": "Hlavní město Praha",
    "liberecky-kraj": "Liberecký kraj",
    "jihocesky-kraj": "Jihočeský kraj",
    "jihomoravsky-kraj": "Jihomoravský kraj",
    "moravsko-slezsky": "Moravskoslezský kraj",
    "ustecky-kraj": "Ústecký kraj",
    "olomoucky-kraj": "Olomoucký kraj"
}

class SearchGuide extends React.Component {

    handleRowOnClick = (url) => {

        const u = new URL(url)
        this.props.history.push(u.pathname);

    }

    render() {
        return (
            <div className="searchGuideMain">
                <div className="searchGuideOffer">
                    <div className="searchGuideTile oneReport">
                        <h3>Náhled ZDARMA</h3>
                        <p>Až 30krát denně můžete stáhnout vytěžená data z účetních závěrek zdarma</p>
                        <p><b>BEZ</b> nutnosti registrace.</p>
                        <a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik" target="_blank">Více informací</a>
                    </div>
                    <div className="searchGuideTile onlinePlatform">
                        <h3>Online platforma</h3>
                        <p>Umožníme Vám přístup do online databáze firem.</p>
                        <p><b>7 dní zdarma po <a href="/registrace">registraci</a>.</b></p>
                        <a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik" target="_blank">Více informací</a>
                    </div>
                    <div className="searchGuideTile exportData">
                        <h3>Export seznamu firem</h3>
                        <p>Připravíme Vám XLSX s možností filtrace firem.</p>
                        <a href="https://www.offiseek.cz/podminky/financni-vykazy/cenik" target="_blank">Více informací</a>
                    </div>
                </div>
                <div>
                    <StatsDashboard />
                </div>

                <div className="regionsBlock">
                    <h2>Řazení firem dle kraje</h2>
                    <div className="regionsBlock">
                        {Object.entries(regionsMap).map(([key, value]) => <RegionTile
                            regionName={value}
                            regionKey={`region/${key}/filtrovani-firem?regions=${value}`} />)}
                    </div>
                </div>
            </div>
        )
    }

}

export default SearchGuide;