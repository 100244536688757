import React from "react";
import './SearchResults.css';
import moment from 'moment';
import CustomDeletedIcon from "../Components/DeletedIcon";
import { CircularProgress } from "@mui/material";

class SearchResults extends React.Component {



    handleRowOnClick = (url) => {

        const u = new URL(url)
        this.props.history.push(u.pathname);

    }

    handleSearch = (page) => {
        this.props.initiateSearch(this.props.history, this.props.searchTerm, page, this.props.pageSize);
        this.props.searchSubmit(this.props.searchTerm, page, this.props.pageSize, this.props.token)
    }

    render() {

        if (this.props.isLoading) {
            return (
                <CircularProgress className="myProgressSpinner" />
            )
        }
        else {


            let pages;
            const pagesCount = this.props.companiesPageCount;
            if (pagesCount < 10) {
                pages = [...Array(this.props.companiesPageCount).keys()].map((value) => (
                    <li key={value} onClick={() => this.handleSearch(value + 1)} className={this.props.page === value + 1 ? 'selectedPage' : ''}>
                        <a>{value + 1}</a>
                    </li>
                ))
            }

            else if (this.props.page < 6) {
                pages = [...Array(10).keys()].map((value) => (
                    <li key={value} onClick={() => this.handleSearch(value + 1)} className={this.props.page === value + 1 ? 'selectedPage' : ''}>
                        <a>{value + 1}</a>
                    </li>
                ))
            }

            else if (pagesCount > 10 && this.props.page > pagesCount - 5) {
                pages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse().map(value => pagesCount - value).map((value) => (
                    <li key={value} onClick={() => this.handleSearch(value)} className={this.props.page === value ? 'selectedPage' : ''}>
                        <a>{value}</a>
                    </li>
                ))
            }
            else {
                pages = [...Array(10).keys()].map(value => this.props.page - 5 + value).map((value) => (
                    <li key={value} onClick={() => this.handleSearch(value + 1)} className={this.props.page === value + 1 ? 'selectedPage' : ''}>
                        <a>{value + 1}</a>
                    </li>
                ))
            }



            return (
                <div className="searchResultsDiv">

                    {console.log('Showing table results')}

                    <div className="searchTableList">

                        <table>
                            <thead>
                                <tr>
                                    <th>Název firmy</th>
                                    <th>Adresa firmy</th>
                                    <th>IČO</th>
                                    <th>Právní forma</th>
                                    <th>Datum založení</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.companies.map((company, ix) =>

                                (
                                    <tr className="clickableTableRow" key={ix} onClick={() => (this.handleRowOnClick(`${company.url.replace('/api/v1/companies', '/firmy')}/${company.web_slug}`))}>
                                        <td><div className="companyCellName">{company.deleted_at ? <CustomDeletedIcon locationType="dashboard" deletedAt={company.deleted_at} /> : null}{company.name}</div></td>
                                        <td>{company.address}</td>
                                        <td>{company.public_id}</td>
                                        <td>{company.legal_form}</td>
                                        <td>{company.created_at ? moment(company.created_at).format('DD/MM/YYYY') : null}</td>
                                    </tr>

                                )
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination">
                        <ul className="paginationNumbers">
                            <li onClick={() => this.handleSearch(this.props.page - 1)}>&laquo;</li>
                            {pages}
                            <li onClick={() => this.handleSearch(this.props.page + 1)}>&raquo;</li>
                        </ul>
                        <div className="paginationLegend">
                            <p>Zobrazujeme výsledky: {((this.props.page - 1) * this.props.pageSize) + 1}-{Math.min(((this.props.page - 1) * this.props.pageSize + this.props.companies.length), this.props.companiesTotalCount)} z celkem {this.props.companiesTotalCount} možných.</p>
                            {
                                this.props.cutOff ?
                                    <p className="paginationLimitation">Zobrazování více jak {this.props.cutOffLimit} výsledků je zpoplatněné</p>
                                    :
                                    ""
                            }
                        </div>
                    </div>

                </div>
            )
        }
    }
}

export default SearchResults;