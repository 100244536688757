
function detailReducer(state, action) {

    switch (action.type) {

        case 'SWITCH_DETAIL_TAB':

            return {
                ...state,
                activeTabId: action.activeTabId
            }

        case 'GET_COMPANY':

            return {
                ...state,
                isCompanyLoading: true,
                documents: null,
                financialData: null
            }

        case 'COMPANY_FETCHED':

            return {
                ...state,
                isCompanyLoading: false,
                company: action.company
            }

        case 'GET_SIMILAR_COMPANIES':

            return {
                ...state,
                isSimilarCompaniesLoading: true
            }

        case 'SIMILAR_COMPANIES_FETCHED':

            return {
                ...state,
                isSimilarCompaniesLoading: false,
                companies: action.companies
            }

        case 'GET_BUSINESS_CATEGORIES':

            return {
                ...state,
                isBusinessCategoriesLoading: true
            }

        case 'BUSINESS_CATEGORIES_FETCHED':

            return {
                ...state,
                isBusinessCategoriesLoading: false,
                businessCategories: action.businessCategories
            }

        case 'GET_FINANCIAL_DATA':

            return {
                ...state,
                isFinancialDataLoading: true
            }

        case 'FINANCIAL_DATA_FETCHED':

            return {
                ...state,
                isFinancialDataLoading: false,
                financialData: action.financialData
            }

        case 'GET_DOCUMENTS':

            return {
                ...state,
                isDocumentsLoading: true
            }

        case 'DOCUMENTS_FETCHED':

            return {
                ...state,
                isDocumentsLoading: false,
                documents: action.documents
            }

        default:
            return state

    }

}

export { detailReducer };