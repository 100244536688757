

import './BigHorizontalBanner.css';

const BigHorizontalBanner = (props) => {

    const isMobile = window.innerWidth < 720;

    const advertsList = [
        {
            "path": "Playgrounds",
            "web": "https://www.ksk-belt.cz/",
            prob: 0.1,
            "heading": "Gumové desky",
            "description": "Podlahy pro fitness centra a dětská hřiště",
            height: 200,
            width: 200,
            logoHeight: 100,
            logoWidth: 200,
            linkType: "web"
        },
        {
            "path": "Carpets",
            "web": "https://www.ksk-belt.cz/",
            prob: 0.1,
            "heading": "Gumové koberce",
            "description": "Podlahy pro průmyslové haly, dílny a stáje",
            height: 200,
            width: 400,
            logoHeight: 100,
            logoWidth: 200,
            linkType: "web"
        },
        {
            "path": "Destilery", "web": "http://www.palenicemalenovice.cz/", prob: 0.05,
            "heading": "Vypálíme, co si přinesete",
            "description": "Malenovice u Kutné Hory",
            height: 200,
            width: 200,
            logoHeight: 100,
            logoWidth: 100,
            linkType: "web"
        },
        {
            "path": "Physio",
            "web": "https://www.physiohub.cz/",
            prob: 0.15,
            "heading": "Fyzioterapie pro všechny",
            "description": "Fyzioterapie, která hledá opravdovou příčinu bolesti",
            height: 200,
            width: 200,
            logoHeight: 100,
            logoWidth: 100,
            linkType: "web"
        },
        {
            "path": "Offiseek",
            "web": "https://www.offiseek.cz",
            prob: 0.6,
            "heading": "5 minut a nastavíte systém pro plánování výroby",
            "description": "Vlastní nástěnky, granulární přístupy a AI",
            height: 123,
            width: 348,
            logoHeight: 100,
            logoWidth: 200,
            linkType: "app"
        }
    ]
    const advertIndex = generateIndexBasedOnProbability(advertsList.map(a => a.prob));

    const topic = advertsList[advertIndex].path;
    const web = advertsList[advertIndex].web;
    const heading = advertsList[advertIndex].heading;
    const description = advertsList[advertIndex].description;
    const height = advertsList[advertIndex].height;
    const width = advertsList[advertIndex].width;
    const logoHeight = advertsList[advertIndex].logoHeight;
    const logoWidth = advertsList[advertIndex].logoWidth;
    const linkType = advertsList[advertIndex].linkType;

    return (<div className={`horizontalBanner ${isMobile ? "smallHorizontalBanner" : "bigHorizontalBanner"}`}>

        <a href={web} target="_blank">
            <p className="advertTag">Reklama</p>

            <div className={`${isMobile ? "horizontalBannerContentVertical" : "horizontalBannerContentHorizontal"}`}>

                <div className="horizontalBannerContentRight">
                    <p className='addLabel'>{heading}</p>
                    <p className='addDescription'>{description}</p>
                    <div>
                        {isMobile ? null : <img className="companyLogo" height={logoHeight} width={logoWidth} src={require(`./Campaigns/${topic}/logo.png`)} />}
                        <a href={web} target="_blank" className="tryoutButton">{linkType === 'app' ? "Vyzkoušet" : "Navštívit"}</a>
                    </div>
                </div>

                <div className='horizontalBannerContentLeft'>
                    {!isMobile ?
                        <img className="advertImage" height={height} width={width} src={require(`./Campaigns/${topic}/image.png`)}></img>
                        : <img className="companyLogo" height={logoHeight} width={logoWidth} src={require(`./Campaigns/${topic}/logo.png`)} />
                    }</div>
            </div>
        </a>

    </div >
    )
}

export default BigHorizontalBanner;

function generateIndexBasedOnProbability(probabilities) {

    const randomNumber = Math.random();
    let cumulativeProb = 0;

    for (let i = 0; i < probabilities.length; i++) {

        cumulativeProb += probabilities[i]
        if (randomNumber <= cumulativeProb) {
            return i;
        }
    }

    return probabilities.length - 1

}