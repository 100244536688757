import "./Header.css"
import React from "react";
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { connect } from "react-redux";
import { logoout } from "../Authentication/state/loginActions";
import { withRouter } from "react-router";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';


class HeaderComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showUserMenu: false,
            showHelpMenu: false
        }
    }

    logout = () => {

        localStorage.removeItem('username');
        localStorage.removeItem('token');
        this.props.logout();
        this.setState({
            showUserMenu: false
        })
        window.location.reload(false);

    }

    showUserMenu = () => {

        if (!this.state.showUserMenu)
            this.setState({
                showUserMenu: true
            })
        else {
            this.setState({
                showUserMenu: false
            })
        }
    }

    showHelpMenu = () => {

        if (!this.state.showHelpMenu)
            this.setState({
                showHelpMenu: true
            })
        else {
            this.setState({
                showHelpMenu: false
            })
        }
    }


    render() {

        return (
            <div className="headerDiv">
                <div className="headlineDiv">
                    <img src={require('../Assets/logo.png')} height="60" alt="Logo icon."></img>
                    <div>
                        <h1><a href="/">Finanční výkazy</a></h1>
                    </div>
                </div>
                <div className="functionsMenu">
                    <div className="functionsMenuItem">
                        <SearchIcon />
                        <a href="/" className="functionsMenuItem">Databáze firem</a>
                    </div>
                    <div className="functionsMenuItem">
                        <FilterAltIcon />
                        <a href="/filtrovani-firem" className="functionsMenuItem">Filtrování firem</a>
                    </div>
                    <div className="functionsMenuItem">
                        <AssessmentIcon />
                        <a href="/statistika-zahranicni-firmy-cr" className="functionsMenuItem">ČR - statistiky</a>
                    </div>
                </div>
            </div>
        )

    }


}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));


function mapStateToProps(state) {

    return {
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username')
    }

}

function mapDispatchToProps(dispatch) {

    return {

        logout: () => { dispatch(logoout()) }

    }

}