import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import searchReducer from './Dashboard/state/searchReducers';
import { detailReducer } from './CompanyDetail/state/detailReducers';
import documentUploadReducer from './DocumentUpload/state/uploadReducers';
import documentDetailReducer from './DocumentDetail/state/documentDetailReducer';
import loginReducer from './Authentication/state/loginReducers';
import filterReducer from './CompanyFiltering/state/filterReducer';
import { advertsReducer } from './Adverts/advertsReducers';
import { limitReducer } from './Limiter/limiterReducers';
import 'bootstrap/dist/css/bootstrap.min.css';

const initialState = {
  adverts: {
    showAdverts: false
  },
  limits: {
    showLimitError: false
  },
  search:
  {
    searchTerm: "",
    companies: null,
    isLoading: true,
    pageSize: 5,
    page: 1,
    companiesTotalCount: null,
    companiesTotalPageCount: null,
    cutOff: null,
    cutOffLimit: null
  },
  detail: {
    activeTabId: null,
    financialData: null,
    documents: null,
    isCompanyLoading: true,
    isFinancialDataLoading: true,
    isSimilarCompaniesLoading: true,
    isBusinessCategoriesLoading: true,
    anonymizeSimilarCompanies: true,
    isDocumentsLoading: true,
    company: null
  },
  documentUpload: {
    filename: null,
    document: null,
    queuedDocuments: [],
    isProcessing: false

  },
  documentDetail: {
    isLoading: true,
    filename: null,
    createdAt: null,
    url: null,
    analysisData: null,
    status: null,
    anonymizeDocumentData: true
  },
  login: {
    loading: false,
    errorMessage: null
  },
  filter: {
    isLoading: false,
    companies: null,
    companiesTotalCount: null,
    companiesTotalPageCount: null,
    cutOff: null,
    cutOffLimit: null,
    markers: {
      "turnover": { "from": 1000000000 },
      "assets": { "from": null, "to": null },
      "liabilities": { "from": null, "to": null },
      "equity_capital": { 'from': null, 'to': null }
  },
    employees_count: [],
    years: ["2020", "2021"],
    entities: ["as", "sro"],
    activities: [],
    regions: [],
    page: 1,
    pageSize: 10
  }
}

const store = createStore(mainReducer, initialState);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

function mainReducer(state, action) {

  return {
    search: searchReducer(state.search, action),
    detail: detailReducer(state.detail, action),
    documentUpload: documentUploadReducer(state.documentUpload, action),
    documentDetail: documentDetailReducer(state.documentDetail, action),
    login: loginReducer(state.login, action),
    filter: filterReducer(state.filter, action),
    adverts: advertsReducer(state.adverts, action),
    limits: limitReducer(state.limits, action)
  }

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
