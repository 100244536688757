



function documentDetailReducer(state, action){

    if(action.type === 'GET_DOCUMENT'){

        return {
            ...state,
            isLoading: true,
            anonymizeDocumentData: true
        }

    }

    if(action.type === 'GET_DOCUMENT_DONE'){

        return {
            ...state,
            isLoading: false,
            filename: action.document.filename,
            url: action.document.url,
            createdAt: action.document.created_at,
            analysisData: action.document.analysis_data,
            status: action.document.status,
            anonymizeDocumentData: action.anonymizeDocumentData
        }

    }

    else{

        return state;

    }

}

export default documentDetailReducer;