import react from "react";
import CustomLineChart from "./CustomLineChart";
import './FinancialData.css';
import CustomTooltip from "../Components/CustomTooltip";
import OrLink from "./OrLink";
import { getDomainById } from "../helpers";
import domainIdLabelMapping from '../domainIdLabelMapping.json';
import CompanyXlsxDownloadButton from "./CompanyXlsxDownloadButton";
import MetricsBlock from "./MetricsBlock";
import { CircularProgress } from "@mui/material";
import BigHorizontalBanner from "../Adverts/BigHorizontalBanner";


class FinancialDataBlock extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItemId: {},
            loading: true
        }


    }

    componentDidMount() {

        this.setState(
            {
                ...this.state,
                loading: false
            }
        )

    }

    selectItem = (e, domainId) => {

        const newSelectedItems = this.state.selectedItemId;
        newSelectedItems[domainId] = e.target.value;
        this.setState({
            selectedItemId: newSelectedItems
        })
    }

    renderGraphs(yearData, domainId, domain) {

        const selectedItemId = (domainId in this.state.selectedItemId && this.state.selectedItemId[domainId]) ? this.state.selectedItemId[domainId] : yearData[0].id;

        return (
            <div>
                <div className="styled-select">
                    <select onChange={e => this.selectItem(e, domainId)} value={selectedItemId}>

                        {yearData.map((item, idx) => (
                            <option key={idx} value={item.id}>{domain[item.id].label}</option>
                        ))}
                    </select>
                </div>

                <div className="collapsableDiv">

                    <p>{domain[selectedItemId].label}</p>

                    <div className="uncollapsedGraph">

                        {<div className="financialDataDiv">
                            <CustomLineChart data={yearData.find(item => item.id === selectedItemId).values.map((d) => { return { name: d.name, hodnota: d.value ? d.value : "Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok." } })} />
                        </div>}

                    </div>
                </div>




            </div>
        )

    }


    render() {

        if (this.state.loading) {
            return (
                <p>Načítá se...</p>
            )
        }

        let financialData;
        if (this.props.financialData && this.props.financialData.length > 0) {
            financialData = this.props.financialData;
        }
        else {
            financialData = [
                { "id": "vykaz_zisku_ztrat", "values": [] },
                { "id": "aktiva", "values": [] },
                { "id": "pasiva", "values": [] }
            ]
        }

        if (!this.props.financialData) {
            return (
                <CircularProgress className="myProgressSpinner" />
            )
        }

        const formatter = Intl.NumberFormat('en-GB');

        return (

            <div className="graphsMain">

                {

                    <div className="domainGraphDiv">

                        {financialData.reduce((totalLength, domain) => totalLength + Object.keys(domain.values).length, 0) > 0 ?
                            <CompanyXlsxDownloadButton
                                company={this.props.company}
                                data={financialData}
                            />
                            :
                            <div></div>
                        }

                        {financialData.map(domain => {

                            const yearData = mapFinancialData(domain.values);

                            const uniqueYears = getUniqueYears(yearData);

                            const domainItems = getDomainById(domain.id)
                            const domainKeys = Object.keys(domainItems);

                            yearData.sort((a, b) => domainKeys.indexOf(a.id) > domainKeys.indexOf(b.id) ? 1 : -1)


                            return (

                                (!yearData || yearData.length === 0) ?

                                    <div>
                                        <div>
                                            <p>V nalezených dokumentech se nám nepodařilo spolehlivě detekovat data pro {domainIdLabelMapping[domain.id]} společnosti.</p>
                                            <p>Originály dokumentů si můžete stáhnout v sekci <b>Účetní závěrky</b> a data si ověřit.</p>
                                        </div>

                                    </div>
                                    :

                                    <div>

                                        <div className="tableList">

                                            <h2 className="domainHeadline">{domainIdLabelMapping[domain.id]}</h2>

                                            {this.renderGraphs(yearData, domain.id, domainItems)}

                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <CustomTooltip title="Hodnota byla ověřena kontrolními součty finančních výkazů z daného roku anebo stejná hodnota byla nalezena i ve výkazech z dalšího roku."><div className="descriptionDot dotVerified"></div></CustomTooltip>
                                                            <CustomTooltip title="Hodnota byla sice nalezena ve finančních výkazech, ale nebylo možné ji ověřit kontrolním součtem nebo porovnat vůči dokumentu z dalšího roku."><div className="descriptionDot dotNotVerified"></div></CustomTooltip>
                                                            <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="descriptionDot greyDot"></div></CustomTooltip>
                                                        </th>
                                                        <th colSpan={uniqueYears.length}><div className="yearTh"><p>Rok</p>
                                                            <p className="termsDiv">Data jsou pravděpodobně tisicích.</p>
                                                        </div></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Položka</th>
                                                        {uniqueYears.map(year => <th>{year}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {yearData.map(item => {
                                                        const values = uniqueYears.map(
                                                            year => item.values.filter(v => v.name === year).map(v =>
                                                                v.value !== null && !v.value_sources.includes("not_found") ?
                                                                    v.value_sources.includes("x_validate") || v.value_sources.includes("x_document_validate") ?
                                                                        <span className="verified">{formatter.format(v.value)}</span>
                                                                        : <span className="not-verified">{formatter.format(v.value)}</span>
                                                                    : <div className="dot"></div>)[0]
                                                        )
                                                        return (
                                                            <tr>
                                                                <td>{domainItems[item.id].label}</td>
                                                                {values.map(v => <td>{v}</td>)}
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>

                                        <BigHorizontalBanner />

                                    </div>)
                        }
                        )}
                    </div>}


                {
                    this.props.orDocumentsUrl ? <OrLink url={this.props.orDocumentsUrl} /> : null
                }

                <MetricsBlock yearData={this.props.financialData} switchTab={this.props.switchTab} user={this.props.user} unlockFailed={this.props.showLimitError} unlockData={(e) => this.unlockData(this.props.match.params.id, this.props.token)} />

            </div>


            // TODO: Show a table with all the years
        )

    }

}

function mapFinancialData(financialData) {

    const filtered = Object.keys(financialData).filter(k => financialData[k].reduce((acc, v) => acc || !v.value_sources.includes("not_found"), false) == true)

    const mapping = filtered.map((key, index) => {

        const sorted = financialData[key].sort((a, b) => (a.year > b.year) ? 1 : -1)

        const data = sorted.map(item => ({ "name": item.year, "value": item.value, "value_sources": item.value_sources }))

        return { id: key, values: data }
    })

    return mapping
}

function getUniqueYears(items) {

    const uniqueYears = [];
    items.map(item => item.values.map(value => {
        if (uniqueYears.indexOf(value.name) === -1) {
            uniqueYears.push(value.name)
        }
    }))

    return uniqueYears;

}

export default FinancialDataBlock;