import React from "react";
import { connect } from "react-redux";
import SimilarCompanyTile from "./SimilarCompanyTile";
import './SimilarCompanies.css';

class SimilarCompanies extends React.Component {

    render() {

        if (this.props.isSimilarCompaniesLoading) {
            return (<p>Načítá se</p>)
        } else {
            return (

                <div>

                    <div className="similarCompanies">

                        {this.props.companies.map(c => <SimilarCompanyTile name={c.name}
                            address={c.address}
                            legal_form={c.legal_form}
                            equity_capital={c.equity_capital}
                            web_slug={c.web_slug}
                            url={c.url}
                        />)}
                    </div></div>)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SimilarCompanies);

function mapStateToProps(state) {

    return {
        isSimilarCompaniesLoading: state.detail.isSimilarCompaniesLoading,
        companies: state.detail.companies,
        activeTabId: state.detail.activeTabId,
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username')
    }

}

function mapDispatchToProps(dispatch) {

    return {

    }

}