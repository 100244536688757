import React from 'react';
import './CompanyFilter.css';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { filterCompany, companyFilterDone } from './state/filterActions';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { checkStatus } from "../helpers";
import CustomTooltip from "../Components/CustomTooltip";
import { Helmet } from 'react-helmet';
import activities from './activities.json';
import regions from './regions.json';
import employees_count from './employees_count.json';
import entities from './entities.json';
import turnoverCodes from './turnover.json';
import assetsCodes from './assets.json';
import liabilitiesCodes from './liabilities.json';
import equityCapitalCodes from './equityCapital.json';
import yearsCodes from './years.json';
import { getValueFromRange, getRangeFromText, generateUrl } from './helpers';
import * as XLSX from 'xlsx';
import { hideAdverts, showAdverts } from '../Adverts/advertsActions';
import FilteringNotPerfectBanner from '../UiRestrictions/FilteringNotPerfectBanner';
import { CircularProgress } from '@mui/material';
import ErrorLimitationBanner from '../Limiter/LimitsExceededBanner';
import BigHorizontalBanner from '../Adverts/BigHorizontalBanner';

const radioValues = ["turnover", "assets", "liabilities", "equity_capital"]
const checkboxValues = ["entities", "years", "employees_count", "activities", "regions"]

const regionsMap = {
    "kralovehradecky-kraj": "Královéhradecký kraj",
    "stredocesky-kraj": "Středočeský kraj",
    "karlovarsky-kraj": "Karlovarský kraj",
    "plzensky-kraj": "Plzeňský kraj",
    "pardubicky-kraj": "Pardubický kraj",
    "zlinsky-kraj": "Zlínský kraj",
    "kraj-vysocina": "Kraj Vysočina",
    "hlavni-mesto-praha": "Hlavní město Praha",
    "liberecky-kraj": "Liberecký kraj",
    "jihocesky-kraj": "Jihočeský kraj",
    "jihomoravsky-kraj": "Jihomoravský kraj",
    "moravsko-slezsky": "Moravskoslezský kraj",
    "ustecky-kraj": "Ústecký kraj",
    "olomoucky-kraj": "Olomoucký kraj"
}

class CompanyFilter extends React.Component {

    constructor(props) {

        super(props);

        if (this.props.match.params.id && this.props.match.params.id in regionsMap) {
            this.regions = [regionsMap[this.props.match.params.id]]
        }

        this.state = {
            activeMenuId: null,
            showGuide: true,
            showApplyButton: true,
            query: {
                entities: props.entities,
                markers: props.markers,
                activities: props.activities,
                employees_count: props.employees_count,
                regions: this.props.match.params.id && this.props.match.params.id in regionsMap ? [regionsMap[this.props.match.params.id]] : props.regions,
                years: props.years
            },
            filterActivitiesText: ""
        }

    }

    componentDidMount() {
        // this.props.filterSubmit(this.state.query.years, this.state.query.entities, this.state.query.activities, this.regions ? this.regions : this.state.query.regions, this.state.query.employees_count, this.state.query.markers, this.props.page, this.props.pageSize, this.props.token);
    }

    createXlsxSheet(companies) {

        const workbook = XLSX.utils.book_new();

        const sheet = XLSX.utils.json_to_sheet([{}], {});

        const companiesData = companies.map(
            company => {
                const itemJson = { "name": company["name"], "id": company["public_id"], "legal_form": company["legal_form"], "url": company["url"] };
                return itemJson
            })

        XLSX.utils.sheet_add_json(sheet, companiesData);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Firmy');
        XLSX.writeFile(workbook, `export.xls`);

    }


    handleRowOnClick = (url) => {
        const u = new URL(url)
        this.props.history.push(u.pathname);
    }

    showApplyButton = (newState) => {
        this.setState({ ...this.state, showApplyButton: true, query: newState })
    }

    handleFilter = (page) => {

        if (!this.props.user) {
            this.props.revealAdverts();
        }

        this.props.initiateFilter(this.props.history, this.state.query.years, this.state.query.entities, this.state.query.activities, this.state.query.regions, this.state.query.employees_count, this.state.query.markers, page, this.props.pageSize, this.props.token)
        this.props.filterSubmit(this.state.query.years, this.state.query.entities, this.state.query.activities, this.state.query.regions, this.state.query.employees_count, this.state.query.markers, page, this.props.pageSize, this.props.token)
    }

    checkboxClick = (e) => {

        const newState = {
            years: this.state.query.years,
            entities: this.state.query.entities,
            markers: this.state.query.markers,
            regions: this.state.query.regions,
            activities: this.state.query.activities,
            employees_count: this.state.query.employees_count
        }

        if (radioValues.includes(e.target.name)) {

            if (e.target.checked) {
                newState['markers'] = { ...this.state.query.markers, [e.target.name]: { ...getRangeFromText(e.target.value), 'value': e.target.value } }
            }
            else {
                newState['markers'] = { ...this.state.query.markers, [e.target.name]: { 'from': null, "to": null, value: null } }

            }

            this.showApplyButton(newState)

        }

        if (checkboxValues.includes(e.target.name)) {

            if (e.target.checked) {

                const newSet = new Set(newState[e.target.name]);
                newSet.add(e.target.value);
                newState[e.target.name] = Array.from(newSet)

                this.showApplyButton(newState)
            }
            else {

                const newSet = new Set(newState[e.target.name]);
                newSet.delete(e.target.value)
                newState[e.target.name] = Array.from(newSet);

                this.showApplyButton(newState)
            }

        }

        e.stopPropagation();

    }

    applyFilterButton = () => {
        this.handleFilter(1)
        this.setState({ ...this.state, activeMenuId: null, showApplyButton: false })
    }

    handleActivitiesFiltering = (e) => {
        e.stopPropagation();
        this.setState({ ...this.state, filterActivitiesText: e.target.value })
    }

    handleValueInput = (name, e) => {

        e.stopPropagation();

        this.setState({ ...this.state, markers: { ...this.state.query.markers, [name]: { ...this.state.query.markers[name], [e.target.id]: e.target.value } } })

    }

    controlItemMenu = (itemId) => {

        if (this.state.activeMenuId != null) {

            if (this.state.activeMenuId != itemId) {
                this.setState({ ...this.state, activeMenuId: itemId });
            }
            else {
                this.setState({ ...this.state, activeMenuId: null });
            }
        }
        else {
            this.setState({ ...this.state, activeMenuId: itemId });
        }
    }

    render() {

        let pages;
        const pagesCount = this.props.companiesPageCount;
        if (pagesCount < 10) {
            pages = [...Array(this.props.companiesPageCount).keys()].map((value) => (
                <li key={value} onClick={() => this.handleFilter(value + 1)} className={this.props.page === value + 1 ? 'selectedPage' : ''}>
                    <a>{value + 1}</a>
                </li>
            ))
        }

        else if (this.props.page < 6) {
            pages = [...Array(10).keys()].map((value) => (
                <li key={value} onClick={() => this.handleFilter(value + 1)} className={this.props.page === value + 1 ? 'selectedPage' : ''}>
                    <a>{value + 1}</a>
                </li>
            ))
        }

        else if (pagesCount > 10 && this.props.page > pagesCount - 5) {
            pages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse().map(value => pagesCount - value).map((value) => (
                <li key={value} onClick={() => this.handleFilter(value)} className={this.props.page === value ? 'selectedPage' : ''}>
                    <a>{value}</a>
                </li>
            ))
        }
        else {
            pages = [...Array(10).keys()].map(value => this.props.page - 5 + value).map((value) => (
                <li key={value} onClick={() => this.handleFilter(value + 1)} className={this.props.page === value + 1 ? 'selectedPage' : ''}>
                    <a>{value + 1}</a>
                </li>
            ))
        }

        let searchResults;
        if (!this.props.companies && !this.props.isLoading) {
            searchResults =
                <div className='emptyResults'>
                    {this.props.showLimitError ? <ErrorLimitationBanner /> : null}
                    <div className="filteringGuide">
                        <p><b>Postup při filtrování:</b></p>
                        <ol className='filteringGuideOl'>
                            <li>Vyberte parametry kategorií</li>
                            <li>Klikněte na <b>Aplikovat parametry</b></li>
                        </ol>
                    </div>
                </div>
        }
        else if (this.props.isLoading) {
            searchResults = <CircularProgress className="myProgressSpinner" />
        }
        else if (this.props.companiesTotalCount > 0) {
            searchResults = <div className='searchResultsDiv'>

                <div className='filterTableList'>

                    <table>
                        <thead>
                            <tr>
                                <th>Název</th>
                                {this.state.query.years.length > 1 ? <th>Rok</th> : null}
                                <th>Obrat</th>
                                <th>Aktiva celkem</th>
                                <th>Pasiva celkem</th>
                                <th>Základní kapitál</th>
                                {/*<th>Počet zaměstnanců</th>*/}
                                <th>Právní forma</th>
                            </tr>
                        </thead>

                        {this.props.companies.map((company, index) => {


                            const rows = this.state.query.years.length > 1 ?

                                <tbody className="clickableTableRow" key={index} onClick={() => (this.handleRowOnClick(`${company.url.replace('/api/v1/companies', '/firmy')}/${company.web_slug}`))}>
                                    {this.state.query.years.sort((a, b) => a < b ? 1 : -1).map((year, year_index) => {

                                        const recentTurnover = getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'turnover').filter(marker => marker.year === parseInt(year)));
                                        const recentLiabilities = getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'assets').filter(marker => marker.year === parseInt(year)));
                                        const recentAssets = getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'liabilities').filter(marker => marker.year === parseInt(year)));

                                        return <tr key={`${index}_${year_index}`}>
                                            {year_index === 0 ? <td rowSpan={this.state.query.years.length}>{company.name}</td> : null}
                                            <td>{year}</td>
                                            <td>{recentTurnover ? recentTurnover.toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                            <td>{recentAssets ? recentAssets.toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                            <td>{recentLiabilities ? recentLiabilities.toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                            <td>{company.equity_capital ? company.equity_capital.toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                            {/* year_index === 0 ? <td rowSpan={this.state.years.length}>{company.employees_count}</td> : null */}
                                            {year_index === 0 ? <td rowSpan={this.state.query.years.length}>{company.legal_form}</td> : null}
                                        </tr>
                                    })
                                    }</tbody>
                                :

                                <tbody>
                                    <tr key={index}>
                                        <td>{company.name}</td>
                                        <td>{getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'turnover').filter(marker => marker.year === this.state.query.years[0])) ? getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'turnover').filter(marker => marker.year === this.state.query.years[0])) : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                        <td>{getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'assets').filter(marker => marker.year === this.state.query.years[0])) ? getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'assets').filter(marker => marker.year === this.state.query.years[0])).toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                        <td>{getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'liabilities').filter(marker => marker.year === this.state.query.years[0])) ? getMostRecentValue(company.markers.filter(marker => marker.marker_id === 'liabilities').filter(marker => marker.year === this.state.query.years[0])).toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                        <td>{company.equity_capital ? company.equity_capital.toLocaleString() : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>}</td>
                                        {/*<td>{company.employees_count}</td>*/}
                                        <td>{company.legal_form}</td>
                                    </tr>
                                </tbody>

                            return rows
                        }).flat()
                        }

                    </table>

                </div>

                <div className="pagination">
                    <ul className="paginationNumbers">
                        <li onClick={() => this.handleFilter(this.props.page - 1)}>&laquo;</li>
                        {pages}
                        <li onClick={() => this.handleFilter(this.props.page + 1)}>&raquo;</li>
                    </ul>
                    <div className="paginationLegend">
                        <p>Zobrazujeme výsledky: {((this.props.page - 1) * this.props.pageSize) + 1}-{Math.min(((this.props.page - 1) * this.props.pageSize + this.props.companies.length), this.props.companiesTotalCount)} z celkem {this.props.companiesTotalCount} možných.</p>
                        {
                            this.props.cutOff ?
                                <p className="paginationLimitation">Zobrazování více jak {this.props.cutOffLimit} výsledků je zpoplatněné</p>
                                :
                                ""
                        }
                    </div>
                </div>

                <BigHorizontalBanner />

            </div>
        }
        else {
            searchResults = <div className="emptyResults">
                <p><b>Pro dotaz nebyly nalezené žádné výsledky.</b></p>
                {this.props.showLimitError ? <p>Vyčerpali jste limit zdarma.</p> : null}
                <div className="filteringGuide">
                    <p><b>Postup při filtrování:</b></p>
                    <ol className='filteringGuideOl'>
                        <li>Vyberte parametry kategorií</li>
                        <li>Klikněte na <b>Aplikovat parametry</b></li>
                    </ol>
                </div>

            </div>
        }

        return (
            <div>

                {this.regions ?

                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{`${this.regions[0]} - firmy - řazení firem dle obratu, aktiv a pasiv`}</title>
                        <meta name="description" content={`${this.regions[0]} - firmy - řazení firem dle obratu, aktiv/pasiv či počtu zaměstnanců.`} />
                        <meta name="og:description" content={`${this.regions[0]} - firmy - řazení firem dle obratu, aktiv/pasiv či počtu zaměstnanců.`} />
                        <meta name="og:title" content={`${this.regions[0]} - firmy`} />
                        <meta property="og:type" content="website" />
                        <link href={window.location.href} rel="canonical"></link>

                    </Helmet>


                    :
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Filtrování firem dle obratu, aktiv a pasiv</title>
                        <meta name="description" content="Řazení firem dle obratu, aktiv, pasiv či dle základního kapitálu? Použijte naši databázi firem." />
                        <meta name="og:description" content="Řazení firem dle obratu, aktiv, pasiv či dle základního kapitálu? Použijte naši databázi firem." />
                        <meta name="og:title" content="Filtrování firem dle obratu, aktiv a pasiv" />
                        <meta property="og:type" content="website" />
                        <link href={window.location.href} rel="canonical"></link>

                    </Helmet>

                }

                {this.regions ?
                    <div>
                        <h1 className="regionHeading">{`${this.regions[0]} - firmy`}</h1>
                        <p>Vyhledejte firmy dle obratu, aktiv/pasiv a počtu zaměstnanců.</p>
                    </div> : null
                }

                <FilteringNotPerfectBanner />

                <div className='filteringMenu'>

                    {this.state.showApplyButton ?
                        <button className='applyButton' onClick={this.applyFilterButton}>Aplikovat parametry</button> : null
                    }

                    <div>
                        <div className={`multiCheckbox ${this.state.query.entities.length > 0 ? 'multiCheckboxFilled' : ''}`} onClick={e => this.controlItemMenu('entities')}>
                            <div className='selectionHeader'>
                                <span>Typ subjektu</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'entities') ?

                                <div className='checkboxDiv'>

                                    {entities.map(item =>
                                        <label className='checkboxLabel'>
                                            <input type="checkbox" name="entities" value={item['code']} checked={this.state.query.entities.includes(item["code"])} onChange={e => { }} onClick={this.checkboxClick} /><span>{item['name']}</span>
                                        </label>)}
                                </div>
                                : null
                            }
                        </div>

                    </div>
                    <div>
                        <div className={`multiCheckbox ${(!this.state.query.markers.turnover.value || this.state.query.markers.turnover.value === 'all') ? 'multiCheckboxUnfilled' : 'multiCheckboxFilled'}`} onClick={e => this.controlItemMenu('turnover')}>
                            <div className='selectionHeader'>
                                <span>Obrat</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'turnover') ?
                                <div className='checkboxDiv'>

                                    {turnoverCodes.map(item => (

                                        <label className='checkboxLabel'>
                                            <input type="radio" name="turnover" value={item["code"]} checked={this.state.query.markers.turnover.value === item["code"]} onChange={e => { }} onClick={this.checkboxClick} /><span>{item["name"]}</span>
                                        </label>
                                    ))}

                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        <div className={`multiCheckbox ${(!this.state.query.markers.assets.value || this.state.query.markers.assets.value === 'all') ? 'multiCheckboxUnfilled' : 'multiCheckboxFilled'}`} onClick={e => this.controlItemMenu('assets')}>
                            <div className='selectionHeader'>
                                <span>Aktiva celkem</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'assets') ?
                                <div className='checkboxDiv'>

                                    {assetsCodes.map(item => (

                                        <label className='checkboxLabel'>
                                            <input type="radio" name="assets" value={item["code"]} checked={this.state.query.markers.assets.value === item["code"]} onChange={e => { }} onClick={this.checkboxClick} /><span>{item["name"]}</span>
                                        </label>
                                    ))}

                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        <div className={`multiCheckbox ${(!this.state.query.markers.liabilities.value || this.state.query.markers.liabilities.value === 'all') ? 'multiCheckboxUnfilled' : 'multiCheckboxFilled'}`} onClick={e => this.controlItemMenu('liabilities')}>
                            <div className='selectionHeader'>
                                <span>Pasiva celkem</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'liabilities') ?
                                <div className='checkboxDiv'>

                                    {liabilitiesCodes.map(item => (

                                        <label className='checkboxLabel'>
                                            <input type="radio" name="liabilities" value={item["code"]} checked={this.state.query.markers.liabilities.value === item["code"]} onChange={e => { }} onClick={this.checkboxClick} /><span>{item["name"]}</span>
                                        </label>
                                    ))}

                                </div>
                                : null
                            }
                        </div>
                    </div>

                    <div>
                        <div className={`multiCheckbox ${(!this.state.query.markers.equity_capital.value || this.state.query.markers.equity_capital.value === 'all') ? 'multiCheckboxUnfilled' : 'multiCheckboxFilled'}`} onClick={e => this.controlItemMenu('equity_capital')}>
                            <div className='selectionHeader'>
                                <span>Základní kapitál</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'equity_capital') ?
                                <div className='checkboxDiv'>

                                    {equityCapitalCodes.map(item => (

                                        <label className='checkboxLabel'>
                                            <input type="radio" name="equity_capital" value={item["code"]} checked={this.state.query.markers.equity_capital.value === item["code"]} onChange={e => { }} onClick={this.checkboxClick} /><span>{item["name"]}</span>
                                        </label>
                                    ))}

                                </div>
                                : null
                            }
                        </div>
                    </div>

                    {

                        <div>
                            <div className={`multiCheckbox ${this.state.query.employees_count.length > 0 ? 'multiCheckboxFilled' : ''}`} onClick={e => this.controlItemMenu('employees_count')}>
                                <div className='selectionHeader'>
                                    <span>Počet zaměstnanců</span>
                                    <ModeEditRoundedIcon fontSize='18px' />
                                </div>
                                {(this.state.activeMenuId === 'employees_count') ?
                                    <div className='checkboxDiv widerCheckBox'>

                                        {employees_count.map(item =>
                                            <label className='checkboxLabel'>
                                                <input type="checkbox" name="employees_count" value={item['code']} checked={this.state.query.employees_count.includes(item["code"])} onChange={e => { }} onClick={this.checkboxClick} /><span>{item['name']}</span>
                                            </label>)}

                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    }
                    <div>
                        <div className={`multiCheckbox ${this.state.query.years.length > 0 ? 'multiCheckboxFilled' : ''}`} onClick={e => this.controlItemMenu('years')}>
                            <div className='selectionHeader'>
                                <span>Období</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'years') ?
                                <div className='checkboxDiv'>

                                    {yearsCodes.map(item =>
                                        <label className='checkboxLabel'>
                                            <input type="checkbox" name="years" value={item['code']} checked={this.state.query.years.includes(item["code"])} onChange={e => { }} onClick={this.checkboxClick} /><span>{item['name']}</span>
                                        </label>)}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        <div className={`multiCheckbox ${this.state.query.activities.length > 0 ? 'multiCheckboxFilled' : ''}`} onClick={e => this.controlItemMenu('activities')}>
                            <div className='selectionHeader'>
                                <span>Činnost {this.state.query.activities.length > 0 ? `(${this.state.query.activities.length})` : null} </span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'activities') ?

                                <div className='checkboxDiv widerCheckBox'>

                                    <input className='filteringInput' type="text" placeholder='Vyhledat činnost' value={this.state.filterActivitiesText} onChange={this.handleActivitiesFiltering} onClick={e => { e.stopPropagation(); }} />

                                    <div className="selectedActivities">
                                        <p>Vybrané činnosti</p>
                                        {this.state.query.activities.map((activity, index) => <span>{index > 0 ? ', ' : ''}<b>{activity}</b></span>)}
                                    </div>

                                    {activities.filter(item => (this.state.filterActivitiesText == '' || item["name"].includes(this.state.filterActivitiesText) || item["code"].includes(this.state.filterActivitiesText))).map(item =>
                                        <label className='checkboxLabel'>
                                            <input type="checkbox" name="activities" value={item['code']} checked={this.state.query.activities.includes(item["code"])} onChange={e => { }} onClick={this.checkboxClick} /><span>{item['code']} - {item['name']}</span>
                                        </label>)}

                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        <div className={`multiCheckbox ${this.state.query.regions.length > 0 ? 'multiCheckboxFilled' : ''}`} onClick={e => this.controlItemMenu('regions')}>
                            <div className='selectionHeader'>
                                <span>Kraj</span>
                                <ModeEditRoundedIcon fontSize='18px' />
                            </div>
                            {(this.state.activeMenuId === 'regions') ?

                                <div className='checkboxDiv'>

                                    {regions.map(item =>
                                        <label className='checkboxLabel'>
                                            <input type="checkbox" name="regions" value={item['code']} checked={this.state.query.regions.includes(item["code"])} onChange={e => { }} onClick={this.checkboxClick} /><span>{item['name']}</span>
                                        </label>)}

                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <button className='multiCheckbox exportButton' onClick={(e) => this.createXlsxSheet(this.props.companies)}>Export</button>
                </div>

                {/*<FilteringBetaBanner /> */}

                {searchResults}
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyFilter));


function mapStateToProps(state) {

    const params = new URLSearchParams(window.location.search);


    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const pageSize = params.get('page_size') ? parseInt(params.get('page_size')) : 10;
    const years = params.get('years') == null ? ["2020", "2021"] : params.get('years').split(",");
    const entities = params.get('entities') == null ? null : params.get('entities').split(",");
    const employees_count = params.get('employees_count') == null ? null : params.get('employees_count').split(",");
    const regions = params.get('regions') == null ? null : params.get('regions').split(",");
    const activities = params.get('activities') == null ? null : params.get('activities').split(",");

    let markers = params.get('markers') ? JSON.parse(params.get('markers')) : state.filter.markers;


    /* Prefill the selected string value of radion buttons */
    radioValues.map(value => {

        if (value in markers) {
            let from_value;
            if ('from' in markers[value]) {
                from_value = markers[value]['from']
            } else {
                from_value = null
            }
            let to_value;
            if ('to' in markers[value]) {
                to_value = markers[value]['to']
            } else {
                to_value = null
            }
            markers[value]["value"] = getValueFromRange(value, from_value, to_value)
        } else {
            markers = { ...markers, [value]: { from: null, to: null, value: null } }
        }
    })

    return {

        isLoading: state.filter.isLoading,
        companies: state.filter.companies,
        companiesTotalCount: state.filter.companiesTotalCount,
        companiesPageCount: state.filter.companiesPageCount,
        cutOff: state.filter.cutOff,
        page: page ? page : state.filter.page,
        pageSize: pageSize ? pageSize : state.filter.pageSize,
        cutOffLimit: state.filter.cutOffLimit,
        token: localStorage.getItem('token'),
        entities: entities ? entities : state.filter.entities,
        years: years ? years : state.filter.years,
        markers: markers,
        employees_count: employees_count ? employees_count : state.filter.employees_count,
        regions: regions ? regions : state.filter.regions,
        activities: activities ? activities : state.filter.activities,
        user: localStorage.getItem('username'),
        showAdverts: state.adverts.showAdverts,
        showLimitError: state.limits.showLimitError
    }
};


function mapDispatchToProps(dispatch) {

    return {
        initiateFilter: (history, years, entities, activities, regions, employees_count, markers, page, pageSize) => {

            history.push({ pathname: window.location.pathname, search: generateUrl({ years: years, entities: entities, activities: activities, regions: regions, employees_count: employees_count, page: page, pageSize: pageSize, markers: markers }) })
            dispatch(filterCompany());
        },
        filterSubmit: (years, entities, activities, regions, employees_count, markers, page, pageSize, token) => {

            filterCompanies(years, entities, activities, regions, employees_count, markers, page, pageSize, token).then(({ results, pagination }) => {
                dispatch(companyFilterDone(results, pagination.total, pagination.total_pages, pagination.cut_off, pagination.cut_off_limit));
            })

        },
        hideAdverts: () => {
            dispatch(hideAdverts());
        },
        revealAdverts: () => {
            dispatch(showAdverts())
        },

    }
};


const filterCompanies = async (years, entities, activities, regions, employees_count, markers, page, pageSize, token) => {

    let urlString;
    if (token) {
        urlString = `${process.env.REACT_APP_API_URL}/api/v1/filter_companies?page=${page}&page_size=${pageSize}`
    } else {
        urlString = `${process.env.REACT_APP_API_URL}/api/v1/filter_companies?page=${page}&page_size=${pageSize}&oneTime=true`
    }

    return fetch(urlString, {
        method: 'post',
        headers: token ? { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } : { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            filter: {
                entities: entities,
                years: years.map(year => parseInt(year)),
                markers: markers,
                activities: activities,
                regions: regions,
                employees_count: employees_count
            }
        }),
    }).then(checkStatus)
        .then((response) => response.json())
}

function getMostRecentValue(listOfPossibleValues) {

    if (listOfPossibleValues && listOfPossibleValues.length > 0) {
        if ("value" in listOfPossibleValues[0]) {
            return listOfPossibleValues[0].value;
        }
    }

    return null;

}